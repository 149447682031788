import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {WartungsabgleichComponent} from '../../submodul/obs.shared/fragmente/wartungsabgleich/wartungsabgleich.component';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DbReaderService} from '../db-reader/db-reader.service';
import {KundeService} from '../kunden/kunde.service';
import {Router} from '@angular/router';

@Injectable()
export class WartungsabgleichService {

  private connection = {
    url: environment.url,
    version: 'API_1_4'
  }
  public wartungsabgleichAktiv: boolean = false

  constructor(
    private dialog: MatDialog,
    private dbService: DbReaderService,
    private kunde_service: KundeService,
    private router: Router,
    private http: HttpClient
  ) {}

  public zeigeWartungsabgleichMeldung(){
    this.kunde_service._kunde_get().subscribe((kunde) => {
      if (kunde) {
        this.kunde_service.kunde_logout(kunde.kundeId).subscribe(() => {
          this.router.navigateByUrl('/login')
        })
      }
    })
    this.wartungsabgleichAktiv = true
    this.dialog.open(WartungsabgleichComponent, { disableClose: true })

    const dbService = this.dbService
    const dialog = this.dialog
    const http = this.http
    const connection = this.connection

    const intervalId = setInterval(function(){
      dbService.get('aktiv', 'Mandant').subscribe(_id => {
        dbService.get(_id, 'Mandant').subscribe(mandant => {
          http.post(connection.url + '/public', {
            command: 'mandant/revisionsCheck',
            client: environment.type,
            parameter: {
              mandantId: environment.mandant_id,
              revision: mandant.revision
            }
          }).subscribe((revision: any) => {
            dialog.closeAll()
            clearInterval(intervalId)
            location.reload()
          })
        })
      })
    }, 60000)
  }
}

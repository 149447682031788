import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'datum'
})
export class DatumPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value != ""){
      if (args == 'wochentag') {
        let wochentage = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
        const date = new Date(value);
        return wochentage[date.getDay()];
      }
      return moment.default(value).format('DD.MM.YYYY')
    }
    else{
      return value;
    }
  }

}

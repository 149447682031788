<!--<div id="navigation">-->
<!--  <button (click)="navigiere('einsehen')" class="navigation" [class.active]="aktuelleUrl == 'einsehen'">-->
<!--    Profil einsehen-->
<!--  </button>-->
<!--  <button (click)="navigiere('rechnungen')" class="navigation" [class.active]="aktuelleUrl == 'rechnungen'">-->
<!--    Rechnungen-->
<!--  </button>-->
<!--</div>-->

<strong>Kundeninformationen</strong>
<div class="profil-optionen-info">
  <div>
    <span>Kundenr.:</span>
    <span>Einrichtung:</span>
  </div>
  <div *ngIf="kunde">
    <span>{{ kunde.kundenNr }}</span>
    <span>{{ kunde.einrichtung.nameEinrichtung }}</span>
  </div>
</div>
<ng-container *ngIf="mandant.mailerAktiv && this.aktuelleUrl == 'einsehen'">
  <strong>Änderungen bestätigen</strong>
  <div class="form-control" id="passwort_bestaetigen">
    <label>Passwort</label>
    <input id="passwort" (input)="change($event)" [disabled]="!mandant.mailerAktiv" [type]="hidePasswort ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hidePasswort = !hidePasswort">{{hidePasswort ? 'visibility_off' : 'visibility'}}</mat-icon>
  </div>
  <button mat-button id="uebernehmen" class="aenderung" type="submit" (click)="onSubmit()" [disabled]="!aktiv && !mandant.mailerAktiv" [class.disabled]="!aktiv">
    <span>Änderungen übernehmen</span>
  </button>
  <div id="reset" class="aenderung" (click)="reset()">
    <span>Änderungen verwerfen</span>
  </div>
</ng-container>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SpeiseplanEinzelbestellerService} from '../../services/speiseplan-einzelbesteller.service';

@Component({
  selector: 'speiseplanTagIcon',
  templateUrl: 'speiseplanTagIcon.component.html',
  styleUrls: ['./speiseplanTagIcon.component.css'],
})
export class SpeiseplanTagIconComponent implements OnInit, OnDestroy{

  @Input() bestellungen
  @Input() config
  @Input() speiseplan

  public bestellt = false
  public bearbeiteterTag
  public bearbeitet = false

  constructor(
    public speiseplanEinzellbesteller : SpeiseplanEinzelbestellerService
  ) {}

  ngOnInit() {
    this.bestellt = this.speiseplanEinzellbesteller.bestellungErkennen(this.bestellungen)
    this.bearbeiteterTag = this.speiseplanEinzellbesteller.bearbeitet.subscribe(result => {
      this.bestellungenBearbeitet(result)
    })
  }

  ngOnDestroy() {
    this.bearbeiteterTag.unsubscribe()
  }

  bestellungenBearbeitet(menue){
    if (menue){
      if (menue.datum == this.bestellungen.datum){
        this.bearbeitet = true
      }
    } else {
      this.bearbeitet = false
    }
  }
}

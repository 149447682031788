import {Injectable } from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';

import mitteilungen_config from 'configs/mitteilungen.config.json';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SnackbarComponent} from '../../submodul/obs.shared/fragmente/snackbar/snackbar.component';

@Injectable()
export class BenachrichtigungService {

  public mitteilungen = new BehaviorSubject<any>(false)
  public benachrichtigungen = new BehaviorSubject<any>(false)

  constructor(
    private snackbar: MatSnackBar,
  ) {
    if (localStorage.getItem('benachrichtigung') == 'true') {
      this.benachrichtigungen.next(true)
    }
  }

  public set_snackbar(code: string, dauer = 5000) {
    let mitteilung = mitteilungen_config.filter((element => {
      if (element.id == code)
        return element.content
    }))
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: mitteilung,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      duration: dauer,
      panelClass: ['snackbar', `${mitteilung[0].typ}`]
    })
  }

  public setMitteilung(value) {
    value = mitteilungen_config.filter((element => {
      if (element.id == value){
        return element
      }
    }))
    this.mitteilungen.next(value[0])
  }

  public checkMitteilung(value){
    value = mitteilungen_config.filter((element => {
      if (element.id == value)
        return element
    }))
    return value[0]
  }

  public setze_benachrichtigungen(kunde) {
    let config = JSON.parse(localStorage.getItem('revision'))
    // let einrichtungs_info = this.einrichtung(kunde, config)
		if (kunde != undefined){
			if (kunde.revision != config) {
				localStorage.setItem('benachrichtigung', 'true')
			} else {
				localStorage.setItem('benachrichtigung', 'false')
			}
		}
  }

  public einrichtung(kunde, config) {
    if (config && kunde.einrichtung.info !== '' && kunde.einrichtung.info !== config.einrichtung) {
      this.benachrichtigungen.next(true)
      return true
    }
    if (!config && kunde.einrichtung.info !== '') {
      this.benachrichtigungen.next(true)
      return true
    }
    return false
  }

  public benachrichtigung_aktualisieren(kunde, check = true, _kunde = null) {
    if (check) {
      if (_kunde && kunde.einrichtung.info !== _kunde.einrichtung.info) {
        localStorage.setItem('benachrichtigung', 'true')
        this.benachrichtigungen.next(true)
      }
    } else {
      if (kunde && kunde.einrichtung.info !== '') {
        localStorage.setItem('benachrichtigung', 'true')
        this.benachrichtigungen.next(true)
      }
    }
  }

  public benachrichtigung_stummschalten() {
    this.benachrichtigungen.next(false)
    localStorage.setItem('benachrichtigung', 'false')
  }
}

export class Mitteilung{
  public code:string
  public content:string
  public dauer: number

  constructor(code, content, dauer){
    this.code = code
    this.content = content
    this.dauer = dauer
  }
}

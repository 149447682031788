import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {EMPTY, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KundeService } from 'services/kunden/kunde.service';
import {MitteilungsService} from 'services/prozesse/mitteilung.service';
import {BestellungService} from '../bestellung/bestellung.service';
import {WartungsabgleichService} from '../prozesse/wartungsabgleich.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FehlerDialogComponent} from '../../submodul/obs.shared/dialog/fehlerDialog/fehlerDialog.component';

@Injectable()
export class ErrorInterceptor {

  constructor(
    public router: Router,
    public kunde_service: KundeService,
    public wartungsabgleichService: WartungsabgleichService,
    private bestell_service: BestellungService,
    public mitteilungen_service: MitteilungsService,
    private matDialog: MatDialog
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(catchError((error) => {
      if (error instanceof DOMException){
        let DomExeption: DOMException = error
        if (DomExeption.message.match('Invalid URL')){
          console.log(req)
          this.matDialog.open(FehlerDialogComponent, {data: {
              message: 'Die angeforderte Website kann nicht geöffnet werden. Die dafür notwendige URL https://proxy.mms-rcs.de ist nicht erreichbar.\n' +
                '<br><br>Bitte probieren Sie es später noch einmal.'
            }})
        }
        return EMPTY
      }
      if (!(error instanceof DOMException) && error.error.content){
        let name = error.error.content.name
        if (name == 'TokenExpiredError'){
          this.kunde_service._kunde_get().subscribe((kunde:any) => {
            this.kunde_service.kunde_logout(kunde.kundeId).subscribe(() => {
              this.bestell_service.bestellungen_leeren()
              this.router.navigateByUrl('/login')
              setTimeout(() => {
                this.mitteilungen_service.setMitteilung('7101')
              }, 100)
            })
          })
        }
        if (error.error && error.error.content.errorType == 'EMandantWartung' && !this.wartungsabgleichService.wartungsabgleichAktiv){
          this.wartungsabgleichService.zeigeWartungsabgleichMeldung()
        }
        if (error.error.content.params){
          if (error.error && error.error.content.params.length == 0 && name != 'TokenExpiredError'){
            this.mitteilungen_service.setMitteilung(JSON.stringify(error.error.content.msgId))
          }
        }
        return throwError(error)
      }
    }))
  }
}

<div id="mandantenMessage" *ngIf="data.mandant.length > 0">
  <strong>Nachricht Ihres Caterers</strong>
  <span [innerHTML]="data.mandant[0].content | base64"></span>
</div>
<div id="globalMessage" *ngIf="data.global.length > 0">
  <strong>Wichtige Ankündigung</strong>
</div>
<body id="global_body"></body>

<button mat-button mat-dialog-close>Schließen</button>

<ng-container *ngIf="formGroup && feldConfig">
  <div [formGroup]="formGroup" class="einrichtung_nameEinrichtung">
    <div class="registrationFeldHeader">
      <label>
        <span>Name</span>
        <strong *ngIf="feldConfig && feldConfig['einrichtung_nameEinrichtung'].pflicht"> *</strong>
      </label>
      <mat-icon
        *ngIf="feldConfig['einrichtung_nameEinrichtung'].description"
        (click)="registrationService.zeigeInfoDialog(feldConfig['einrichtung_nameEinrichtung'].description)">info</mat-icon>
    </div>
    <select
      (change)="onChangeEinrichtungsName()"
      [class.registrationFormFehler]="formGroup.controls['einrichtung_nameEinrichtung'].errors"
      [formControlName]="'einrichtung_nameEinrichtung'"
      [tabIndex]="36"
      type="text"
    >
      <option [value]=""></option>
      <option *ngFor="let option of feldConfig['einrichtung_nameEinrichtung'].options" [value]="option">{{ option }}</option>
    </select>
    <span
      class="registrationBeschreibungFehler"
      *ngIf="formGroup.controls['einrichtung_nameEinrichtung']"
    >{{ formGroup.controls['einrichtung_nameEinrichtung'].errors | error }}</span>
  </div>
  <div *ngIf="feldConfig['einrichtung_nameGruppe'].active" [formGroup]="formGroup" class="einrichtung_nameGruppe">
    <div class="registrationFeldHeader">
      <label>
        <span>Klasse / Gruppe</span>
        <strong *ngIf="feldConfig['einrichtung_nameGruppe'].pflicht"> *</strong>
      </label>
      <mat-icon *ngIf="feldConfig['einrichtung_nameGruppe'].description" (click)="registrationService.zeigeInfoDialog(feldConfig.description)">info</mat-icon>
    </div>
    <select
      [class.registrationFormFehler]="formGroup.controls['einrichtung_nameGruppe'].errors"
      [formControlName]="'einrichtung_nameGruppe'"
      [tabIndex]="37"
      [attr.disabled]="einrichtungsGruppen && einrichtungsGruppen.length > 0 ? null : ''"
      type="text"
    >
      <option [value]=""></option>
      <option
        *ngFor="let option of einrichtungsGruppen"
        [value]="option"
      >{{ option }}</option>
    </select>
    <span
      class="registrationBeschreibungFehler"
      *ngIf="formGroup.controls['einrichtung_nameGruppe']"
    >{{ formGroup.controls['einrichtung_nameGruppe'].errors | error }}</span>
  </div>
</ng-container>

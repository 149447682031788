import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {SeitenleisteService} from '../seitenleiste.service';
import {Subscription} from 'rxjs';
import {SeitenleisteInhalt} from '../seitenleisteInhalt.class';


@Component({
	selector: 'app-seitenleiste',
	templateUrl: './seitenleiste.component.html',
	styleUrls: ['./seitenleiste.component.css'],
})
export class SeitenleisteComponent implements OnInit, AfterViewInit, OnDestroy {

  public inhaltSubscription: Subscription
  @ViewChild('inhalt', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private seitenleistenService: SeitenleisteService,
	) { }

	ngOnInit() {
	}

	ngAfterViewInit() {
    this.inhaltSubscription = this.seitenleistenService.inhaltSeitenleiste.subscribe((seitenleisteInhalt: SeitenleisteInhalt) => {
      if (seitenleisteInhalt){
        this.seitenleistenService.renderSeitenleisteInhalt(this.container)
      }
    })
  }

  ngOnDestroy() {
    this.inhaltSubscription.unsubscribe()
  }
}

import {AfterViewInit, Component, OnInit} from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import {Platform} from '@angular/cdk/platform';
import {MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../../environments/environment';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {PwaInstallComponent} from '../pwa-install/pwa-install.component';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})

export class FaqComponent implements OnInit, AfterViewInit {
  public detectedPlattform: string
  public content:any = []
  displayedColumns: string[] = ['position'];
  public dataSource
  public kunde

  constructor(
    private app_state: AppStateService,
    public platform: Platform,
    public content_service: ContentBlockService,
    private kunden_service: KundeService,
  ) {
    this.app_state.set_page_name('Faq');
    this.app_state.set_seitenname('faq')
    this.app_state.set_page_navigation('normal');
  }

  ngOnInit() {
    this.kunden_service._kunde_get().subscribe(kunde => {
      this.kunde = kunde;
      if (environment.type == 'mobil') {
        this.detectedPlattform = 'MOBIL'
        this.datenPruefen('App_FAQ')
      } else {
        this.detectedPlattform = 'WEB'
        this.datenPruefen('Web_FAQ')
      }
    })
  }

  ngAfterViewInit() {
  }

  datenPruefen(zweck){
    this.content_service.getContentBlock(zweck).subscribe(result => {
      this.contentFilter(result)
      if (result){
        this.content = result
      }
    })
  }

  contentFilter(inhalt){
    if (inhalt){
      const meinString = inhalt
      const contentListe = meinString.split('_ende')
      contentListe.pop()
      const abschnitt = [];

      for (let auswahl of contentListe){
        let status = auswahl.includes('_uneingeloggt') ? true : false
        const inhaltMatch = auswahl.split('_titel')[1].split('_inhalt');
        const titelMatch = inhaltMatch[0];
        const contentMatch = inhaltMatch[1].split('_ende')[0];
        abschnitt.push({name: titelMatch, content: contentMatch, status: status})
      }
      this.checkUpUneingeloggt(abschnitt)
    } else {
      if (environment.type == 'mobil'){
        this.dataSource = new MatTableDataSource()
        this.dataSource._data._value.unshift({name: "Installationsanleitung", content: PwaInstallComponent})
      }
    }
  }

  checkUpUneingeloggt(liste){
    if (!this.kunde){
      liste = liste.filter(block => block.status);
    }
    this.dataSource = new MatTableDataSource(liste);
    if (environment.type == 'mobil'){
      this.dataSource._data._value.unshift({name: "Installationsanleitung", content: PwaInstallComponent})
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter(){
    this.dataSource.filter = '';
    (document.getElementById('inputFilter') as HTMLInputElement).value = '';
  }

    protected readonly environment = environment;
}

<div id="vertragKuendigenDialog">
  <mat-card id="vertragKuendigenForm" [class.wbp]="environment.type == 'web'">
    <div id="vertragKuendigenIntro">
      <strong>Kündigungserklärung</strong>
      <br>
      <span>
        Mit diesem Formular können Sie Ihre Kündigungserklärung abgeben. Bitte beachten Sie, dass Ihr Kündigungswunsch erst
        durch den Caterer bestätigt werden muss.
      </span>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" [class.wbp_form]="environment.type == 'web'">
      <div class="kontakt-data" id="kundenummer" *ngIf="!formConfig.kundenNr.hidden">
        <label for="kundenNr">Kundennumnmer
          <strong class="pflicht"> *</strong>
        </label>
        <input formControlName="kundenNr" tabindex="2">
      </div>
      <div class="kontakt-data" id="kundennummer" *ngIf="mandant && mandant.superMandantType === 'super'">
        <label for="login">Kundennumnmer
          <strong class="pflicht"> *</strong>
        </label>
        <input formControlName="login"  tabindex="2">
      </div>
      <div class="kontakt-data" id="anrede" *ngIf="!formConfig.anrede.hidden">
        <label for="anrede">Anrede
        </label>
        <input formControlName="anrede" tabindex="1">
      </div>
      <div class="kontakt-data" id="vorname" *ngIf="!formConfig.vorname.hidden">
        <label for="vorname">Vorname
          <strong class="pflicht"> *</strong>
        </label>
        <input formControlName="vorname" tabindex="3">
      </div>
      <div class="kontakt-data" id="name" *ngIf="!formConfig.name.hidden">
        <label for="name">Nachname
          <strong class="pflicht"> *</strong>
        </label>
        <input formControlName="name"  tabindex="5">
      </div>
      <div class="kontakt-data" id="email" *ngIf="!formConfig.eMail.hidden">
        <label for="eMail">E-Mail
          <strong class="pflicht"> *</strong>
        </label>
        <input formControlName="eMail" tabindex="4">
      </div>
      <div class="kontakt-data" id="vertrag" *ngIf="!formConfig.vertrag.hidden">
        <label for="eMail">Bezeichnung des Vertrags</label>
        <input formControlName="vertrag"  tabindex="7">
      </div>
      <div class="kontakt-data textareaContainer" id="grund" *ngIf="!formConfig.grund.hidden">
        <label for="grund">Grund bei einer außerordentlichen Kündigung</label>
        <textarea formControlName="grund" tabindex="8"></textarea>
      </div>
      <div class="kontakt-data" id="termin" *ngIf="!formConfig.termin.hidden">
        <label for="termin">Termin
          <strong class="pflicht"> *</strong>
        </label>
        <input formControlName="termin" type="date" id="vertragKuendigenTermin" tabindex="6">
      </div>
      <button mat-flat-button [disabled]="!form.valid" id="vertragKuendigenSubmit">
        <i class="fas fa-paper-plane"></i>
        Vertrag kündigen
      </button>
    </form>
    <span id="vertragKuendigenHinweis">mit * markierte Felder sind Pflicht</span>
  </mat-card>
</div>

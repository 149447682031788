<div id="seitenleiste">
  <div id="seitenleisteImage">
    <img src="assets/theme/img/logo.webp"/>
  </div>
  <div id="seitenleistenInhalt">
    <div>
      <ng-container #inhalt></ng-container>
    </div>
  </div>
</div>

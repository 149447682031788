<div id="footer">
  <div id="footer-menu">
    <a *ngIf="rechts_content && rechts_content[0].content" mat-menu-item [routerLink]="'/rechtliches'"
      [queryParams]="{id: 'AGB'}">
      <span>AGB</span>
    </a>
    <a *ngIf="rechts_content && rechts_content[1].content" mat-menu-item [routerLink]="'/rechtliches'"
       [queryParams]="{id: 'Datenschutz'}">
      <span>Datenschutz</span>
    </a>
    <a *ngIf="rechts_content && rechts_content[2].content" mat-menu-item [routerLink]="'/rechtliches'"
      [queryParams]="{id: 'Widerruf'}">
      <span>Widerruf</span>
    </a>
    <a *ngIf="rechts_content && rechts_content[4]" mat-menu-item [routerLink]="'/rechtliches'"
      [queryParams]="{id: 'Impressum'}">
      <span>Impressum</span>
    </a>
    <a mat-menu-item [routerLink]="'/rechtliches'" [queryParams]="{id: 'Kontakt'}">
      <span>Kontakt</span>
    </a>
    <a *ngIf="kuendigung != ''" mat-menu-item (click)="kuendigen();">
      <span>Kündigung</span>
    </a>
    <a *ngIf="faq || kunde" [routerLink]="'/rechtliches'" mat-menu-item [queryParams]="{id: 'FAQ'}">
      <span>FAQ</span>
    </a>
  </div>
  <span id="footer-info" gitVersion>Version #33286</span>
</div>

<div id="hauptcontent" *ngIf="bestellungen_meta">
  <mat-card class="bestelltag-kopf">
    <strong>Menü</strong>
    <strong>Beschreibung</strong>
    <strong>Menge</strong>
    <strong>Änderung</strong>
    <strong *ngIf="preis_anzeige">Einzelpreis</strong>
    <strong *ngIf="preis_anzeige">Summe</strong>
  </mat-card>

  <div id="warenkorb" *ngIf="bestellungen_meta && bestellungen_meta.anzahl > 0 && bestellungen">
    <table *ngFor="let tag of bestellungen.tage | keyvalue">
      <tr class="bestelltag">
        <div class="bestelltag-menues">
          <strong>{{ tag.key | datum }}</strong>
          <ng-container  *ngFor="let menu of tag.value.bestellMenues | keyvalue; index as zeile">
            <div [class.lonely]="zeile == 0" class="bestellungtag-zeile" *ngIf="menu.value.mengeNeu || menu.value.mengeAlt != 0">
              <div class="bestellungtag-zeile-content">
                <td>{{ menutexte[menu.value.menueTextId].bezeichnung }}</td>
                <td [innerHTML]="menutexte[menu.value.menueTextId].text | bbCode"></td>
                <td>{{ menu.value.mengeNeu }}</td>
                <td bestellung-update [bestellung]="menu.value">{{ menu.value.mengeNeu - menu.value.mengeAlt }}</td>
                <td *ngIf="preis_anzeige">{{ menu.value.einzelPreis | euroPrice}}</td>
                <td *ngIf="preis_anzeige">{{ menu.value.gesamtBetrag | euroPrice }}</td>
              </div>
              <span class="menu-fehler" *ngIf="menu.value.error">{{ menu.value.error.msgId | speiseplan_fehler }}</span>
            </div>
          </ng-container>
        </div>
      </tr>
    </table>
  </div>
  <div id="warenkorbLeer" *ngIf="bestellungen_meta.anzahl == 0">
    <mat-icon>remove_shopping_cart</mat-icon><div>
  </div>
</div>

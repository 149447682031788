import {Injectable, ViewContainerRef} from '@angular/core';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {MatTableDataSource} from '@angular/material/table';

@Injectable()
export class FaqService {

  public faqAnzeige: BehaviorSubject<any> = new BehaviorSubject(false)
  public zweck

  constructor(
    private content_service: ContentBlockService
  ) {
    if (environment.type == 'web'){
      this.zweck = 'Web_FAQ'
    } else {
      this.zweck = 'App_FAQ'
    }
  }

  faqUneingeloggtAnzeigen(){
    this.content_service.getContentBlock(this.zweck).subscribe(result => {
      if (result){
        this.faqAnzeige.next(false)
        this.contentFilter(result)
      }
    })
  }

  contentFilter(inhalt){
    const contentListe = inhalt.split('_ende')
    contentListe.pop()

    for (let auswahl of contentListe){
      if (auswahl.includes('_uneingeloggt')){
        this.faqAnzeige.next(true)
      }
    }
  }
}


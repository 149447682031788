import {Component, ViewChild, ElementRef, Renderer2, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import {DbReaderService} from '../../../../services/db-reader/db-reader.service';

@Component({
  selector: 'speiseplan-glossar',
  templateUrl: './speiseplan-glossar.component.html',
  styleUrls: ['./speiseplan-glossar.component.css']
})
export class SpeiseplanGlossarComponent implements OnChanges, OnInit, AfterViewInit{

  @Input('mandant') mandant
  @Input('menu') menu
  @ViewChild('glossar_liste') glossar_liste: ElementRef
  public glossar = [
    { 'name': 'Allergene', 'data': {} },
    { 'name': 'Inhaltsstoffe', 'data': {} },
    { 'name': 'Zusatzstoffe', 'data': {} },
  ]
  public allergene: boolean = false
  public allergeneKeys
  public inhaltsstoffeKeys
  public zusatzstoffeKeys

  constructor(
    private renderer: Renderer2,
    private db: DbReaderService,
  ) { }

  ngOnInit() {
    this.db.all('Options', 'keys').subscribe(keys => {
      this.db.all('Options', 'value').subscribe(value => {
        for (let i = 0; i < keys.length; i++) {
          this[keys[i]] = value[i]
        }
        this.ngOnChanges()
      })
    })
  }

  ngAfterViewInit(){
  }

  ngOnChanges() {
    this.allergene = false
    let allergeneKeys = this.mandant.allergene
    let inhaltsstoffeKeys = this.mandant.inhaltsstoffe
    let zusatzstoffeKeys = this.mandant.zusatzstoffe
    if (this.allergene){
        if (this.menu['allergene'].length > 0 || this.menu['inhaltsstoffe'].length > 0 || this.menu['zusatzstoffe'].length > 0){
          allergeneKeys = this.menu['allergene']
          inhaltsstoffeKeys = this.menu['inhaltsstoffe']
          zusatzstoffeKeys = this.menu['zusatzstoffe']
        }
      else {
        this.alle_AIZ(allergeneKeys, inhaltsstoffeKeys, zusatzstoffeKeys)
      }
    }
    // }
    else {
      this.alle_AIZ(allergeneKeys, inhaltsstoffeKeys, zusatzstoffeKeys)
    }

    this.glossar[0].data = []
    this.glossar[1].data = []
    this.glossar[2].data = []
    Object.keys(allergeneKeys).map(i => {this.glossar[0].data[i] = this.findeUeberschneidungen(this.mandant.allergene, this.allergeneKeys, i)})
    Object.keys(inhaltsstoffeKeys).map(i => {this.glossar[1].data[i] = this.findeUeberschneidungen(this.mandant.inhaltsstoffe, this.inhaltsstoffeKeys, i)})
    Object.keys(zusatzstoffeKeys).map(i => {this.glossar[2].data[i] = this.findeUeberschneidungen(this.mandant.zusatzstoffe, this.zusatzstoffeKeys, i)})
    }

  public findeUeberschneidungen(array1: any[], array2: any[], i): any[] {
    const ueberschneidungen = [];
    for (const [index, value] of Object.entries(array1)){
        if (array2[i] == value.schluessel){
          ueberschneidungen.push(value)
      }
    }
    return ueberschneidungen;
  }

  public alle_AIZ(allergeneKeys, inhaltsstoffeKeys, zusatzstoffeKeys){
    let allergene = [], inhalt = [], zusatz = []
    let value, keys: any
    for ([keys, value] of Object.entries(allergeneKeys)){allergene.push(value.schluessel)}; this.allergeneKeys = allergene
    for ([keys, value] of Object.entries(inhaltsstoffeKeys)){inhalt.push(value.schluessel)}; this.inhaltsstoffeKeys = inhalt
    for ([keys, value] of Object.entries(zusatzstoffeKeys)){zusatz.push(value.schluessel)}; this.zusatzstoffeKeys = zusatz
  }

  public statusWechsel(item){
    item.expanded = item.expanded ? false : true
  }

  public statusAnzeigen(item){
    return item.expanded
  }
}

<mat-card class="recht" *ngIf="rechts_content && rechts_txt" [class.faq-content]="name == 'FAQ'">
  <div id="recht-content" [class.recht-content-kontakt]="name == 'Kontakt'" [class.faq-content]="name == 'FAQ'">
    <div *ngIf="name != 'Kontakt' && name != 'Kündigung' && name != 'FAQ'" class="recht_text" >
      <div class="recht_text_inhalt" [innerHTML]="content"></div>
    </div>
    <kontakt *ngIf="name == 'Kontakt'" class="recht_text" ></kontakt>
    <vertrag-kuendigen *ngIf="name == 'Kündigung'"></vertrag-kuendigen>
    <faq *ngIf="name == 'FAQ'"></faq>
  </div>
</mat-card>

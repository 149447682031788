import {environment} from '../../environments/environment';

export class ContentBlock{
  public mandantId: string = environment.mandant_id
  public contentItemIds: string = JSON.stringify([])
  public zweck: string
  public lang: string = 'de'

  constructor(zweck:string) {
    this.zweck = zweck
  }

}

import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KundeService } from '../kunden/kunde.service';
import { RevisionService } from 'services/api/revision.service';

@Injectable()
export class HTTPInterceptorService {

  constructor(
    public router: Router,
    public kunde_service: KundeService,
    private revisions_service: RevisionService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.token) {
            localStorage.setItem(event.body.content.kundeId, event.body.token)
          }
          // this.revisions_service.revision_mandant(req.body.command)
          // this.revisions_service.revision_kunde(req.body.command)
          return event = event.clone({ body: event.body.content })
        }
      })
    ) as Observable<HttpEvent<any>>
  }
}

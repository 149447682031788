import { Component, OnInit } from '@angular/core';
import {ContentBlock} from '../../../../../services/prototype/contentBlock.class';
import {zip} from 'rxjs';
import {StateService} from '../../../../../services/app/state.service';
import {ApiCallService} from '../../../../../services/api/api-call.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {WichtigeMitteilungenDialogComponent} from '../wichtigeMitteilung-dialog/wichtigeMitteilungen-dialog.component';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'wichtigeMitteilungen-icon',
    templateUrl: './wichtigeMitteilungen-icon.component.html',
    styleUrls: ['./wichtigeMitteilungen-icon.component.css'],
})
export class WichtigeMitteilungenIconComponent implements OnInit {

  public toolbarNachricht: boolean | object = false

  constructor(
    private stateService: StateService,
    private apiCallService: ApiCallService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(){
    this.stateService.mandant.subscribe(mandant => {
      let suffix = environment.type == 'web' ? 'Web' : 'App'
      zip(this.apiCallService.request(
        '/public',
        'content/get',
        new ContentBlock(`${suffix}_MandantenMessage`)
      ), this.apiCallService.request(
        '/public',
        'content/get',
        new ContentBlock(`${suffix}_GlobalMessage`)
      )).subscribe((contentBlocks: Array<any>) => {
        if (contentBlocks[0].length > 0 || contentBlocks[1].length > 0){
          this.toolbarNachricht = {}
          this.toolbarNachricht['mandant'] = contentBlocks[0]
          this.toolbarNachricht['global'] = contentBlocks[1]
        }else{
          this.toolbarNachricht = false
        }
      })
    })
  }

  dialogOpen(){
    this.matDialog.open(WichtigeMitteilungenDialogComponent, {data: this.toolbarNachricht})
  }

  protected readonly environment = environment;
}

<div id="menu" *ngIf="mandant">
  <div id="menu_header">
    <div id="toolbar-navigation">
      <a (click)="setLinkInactive(1); home_link()" *ngIf="home_config()" [class.router-link-active]="router.url.includes('/home')"
              routerLinkActive="router-link-active"><mat-icon>home</mat-icon>Home</a>

      <webToolbarPubSplan *ngIf="!kunde && this.mandant.navigation_root.menu" [mandant]="mandant"></webToolbarPubSplan>

      <a *ngIf="kunde" (click)="setLinkInactive(2)" [routerLink]="'/speiseplan-kunde'"
              routerLinkActive="router-link-active"><mat-icon>restaurant</mat-icon>Speiseplan</a>
      <a *ngIf="kunde && kunde.sammelbesteller" (click)="setLinkInactive(2)" [routerLink]="'/sammelbesteller'"
         routerLinkActive="router-link-active"><mat-icon>menu_book</mat-icon>Sammelbesteller</a>
      <a *ngIf="kunde" (click)="setLinkInactive(2)" [routerLink]="'/warenkorb'" routerLinkActive="router-link-active"
              [class.heartbeat]="this.bestell_service._bestellungen_meta.anzahl > 0 ? this.bestell_service._bestellungen_meta.anzahl : null"
      ><mat-icon>shopping_cart</mat-icon>
      <span
        [matBadge]="this.bestell_service._bestellungen_meta.anzahl > 0 ? this.bestell_service._bestellungen_meta.anzahl : null"
        matBadgeSize="small"
      >Warenkorb</span>
      </a>
      <a *ngIf="kunde" (click)="setLinkInactive(2)" [routerLink]="'/bestellhistorie'"
              routerLinkActive="router-link-active"><mat-icon>date_range</mat-icon>Bestellhistorie</a>
    </div>
  </div>

  <div *ngIf="!kunde" id="anmelden">
    <a class="farbkatalog" (click)="toggleSidebar()" *ngIf="farbkatalogAktiv()"><mat-icon>palette</mat-icon>Farbkatalog</a>
    <a *ngIf="!kunde && mandant && mandant.darstellungRegistrierung != 3 && mandant.mailerAktiv" (click)="setLinkInactive(2)" [routerLink]="registrationLink()"
       routerLinkActive="router-link-active"><mat-icon>app_registration</mat-icon>Registrieren</a>
    <a *ngIf="!kunde" [routerLink]="'/login'" routerLinkActive="router-link-active"><mat-icon>login</mat-icon>Anmelden</a>
  </div>

  <div class="toolbar-profildiv" *ngIf="kunde">
    <a class="farbkatalog" (click)="toggleSidebar()" *ngIf="farbkatalogAktiv()"><mat-icon>palette</mat-icon>Farbkatalog</a>
    <div class="guthaben" *ngIf="guthaben">
      <mat-icon>savings</mat-icon>
      <span>{{ guthaben | euroPrice }}€</span>
    </div>
    <div id="toolbar-profil" *ngIf="kunde">
      <button mat-button [matMenuTriggerFor]="profil" id="profil" class="profil-button">
        {{ kunde.essensteilnehmer.vorname }} {{kunde.essensteilnehmer.name}}
        <mat-icon>arrow_drop_down</mat-icon>
			</button>
      <mat-menu #profil="matMenu" class="profilMenu">
        <a mat-menu-item [routerLink]="'/profil'" [queryParams]="{id: 'einsehen'}" (click)="setLinkActive(2)" (click)="setLinkInactive(2)" class="profil-button">
          <mat-icon>people</mat-icon>
          Benutzer bearbeiten
        </a>
<!--        <a-->
<!--          mat-menu-item-->
<!--          [routerLink]="'/profil'"-->
<!--          [queryParams]="{id: 'rechnungen'}"-->
<!--          (click)="setLinkActive(2)"-->
<!--          (click)="setLinkInactive(2)"-->
<!--          class="profil-button"-->
<!--        >-->
<!--          <mat-icon>receipt_long</mat-icon>-->
<!--          Rechnungen-->
<!--        </a>-->
        <a mat-menu-item (click)="logout(kunde.kundeId)" class="profil-button">
          <mat-icon>logout</mat-icon>
          Abmelden
        </a>
      </mat-menu>
    </div>
  </div>
</div>

import {Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiCallService} from '../api/api-call.service';
import {DbReaderService} from '../db-reader/db-reader.service';
import {map} from 'rxjs/operators';

@Injectable()
export class ContentBlockService {

  constructor(
    private apiCallService: ApiCallService,
    private dbReaderService: DbReaderService
  ) {
  }

  public getContentBlock(zweck){
    return this.dbReaderService.get(zweck, 'Content').pipe(
      map(data => {
        if (data){
          return atob(data.content)
        }
      })
    )
  }

  public setContentBlock(block){
    this.apiCallService.request('/public', 'content/get', {
      mandantId: environment.mandant_id ,
      'contentItemIds': JSON.stringify([]),
      zweck: block.zweck,
      lang: 'de'
    }).subscribe((result:any) => {
      let neuBlock = { ...block, content: result[0].content }
      this.dbReaderService.add(neuBlock, block.zweck ,'Content')
    })
  }

  public contentBlockRevision(liste){
    for (let block of liste){
      this.dbReaderService.get(block.zweck ,'Content').subscribe(result => {
        if (result){
          if (block.revision != result.revision){
            this.setContentBlock(block)
          }
        } else {
          this.setContentBlock(block)
        }
      })
    }
  }

  public contentBlockAnlegen(liste){
    for (let block of liste){
      this.setContentBlock(block)
    }
  }
}

<div id="pwaInstallDialog">
  <div id="pwaInstallIntro">
    <strong *ngIf="mandant">Web-Bestell-App von {{ mandant.betreiberDaten.name }}</strong>
    <br>
    <span>
    Installieren Sie unsere Web-Bestell-App auf dem Home-Bildschirm und greifen Sie schnell und unkompliziert auf Ihre Speisepläne und Bestellungen zu.
  </span>
  </div>

  <div id="pwaInstallManual">
    <strong>Und so geht's</strong>
    <ul>
      <li *ngFor="let manualStep of pwaInstallManual[detectedPlattform]" >{{ manualStep }}</li>
    </ul>
  </div>
  <div id="pwaInstallManualButton">
    <div (click)="anleitung()">
      <img *ngFor="let bild of bilder" src="{{bild.src}}" aria-label="Anleitung">
    </div>
  </div>
  <span *ngIf="detectedPlattform == 'ANDROID'" class="pwaInstallManualFeedback">
    Das Icon der Web-Bestell-App ist nun im Hauptmenü und kann bei Bedarf auf dem Home-Bildschirm platziert werden.
  </span>
  <span *ngIf="detectedPlattform == 'SAFARI'" class="pwaInstallManualFeedback">
    Das Icon der Web-Bestell-App ist nun auf dem Home-Bildschirm platziert. Die App kann geöffnet werden.
  </span>

  <span id="pwaInstallAccess">Die Anleitung zur Installation des Schnellzugriffes kann jederzeit im Hilfebereich der Web-Bestell-App unter „Schnellzugriff“ nachgelesen werden.</span>

</div>

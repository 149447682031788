<form *ngIf="vertragsId && mandant && formGroup" id="registrierungsFormular" [class.web]="detectedPlattform === 'WEB'" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <mat-accordion multi>
    <mat-expansion-panel [expanded]="true" #vertragsText  id="vertrag_text" *ngIf="mandant.registrierFormen[this.vertragsId].registrierArt !== 2" [class.expansionPanelWeb]="detectedPlattform == 'WEB'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="kartenKopf">
            <strong>{{ mandant.registrierFormen[vertragsId].name }}</strong>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
        <app-registrierung-vertrag [vertragsId]="vertragsId" [mandant]="mandant"></app-registrierung-vertrag>
    </mat-expansion-panel>
    <ng-container *ngIf="mandant.registrierFormen[this.vertragsId].registrierArt !== 1" >
      <mat-expansion-panel appRegistrierungFormularParser [formConfig]="feldConfig" formAbschnitt="vertragspartner" #vertragspartner id="vp"
                           [expanded]="true" [class.expansionPanelWeb]="detectedPlattform == 'WEB'"
      >
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="vertragspartnerKarte" class="kartenKopf">
              <strong *ngIf="enviroment.envName == '110129-gloria'">Ihre Kundendaten</strong>
              <ng-container *ngIf="enviroment.envName != '110129-gloria'"><strong>Vertragspartner</strong><span>(ggf. gesetzlicher Vertreter)</span></ng-container>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [ngClass]="detectedPlattform != 'WEB' ? 'personenAngabenApp' : 'personenAngabenWeb'" id="vertragspartner">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['vertragspartner']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #essensteilnehmer appRegistrierungFormularParser [viewChild]="essensteilnehmer" [formConfig]="feldConfig" formAbschnitt="essensteilnehmer"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'" id="et">
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="essensteilnehmerKarte" class="kartenKopf">
              <strong>Essensteilnehmer</strong><span> (falls abweichend)</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span *ngIf="enviroment.envName == '110129-gloria'" class="formularHinweis">Bitte ausfüllen, wenn abweichend von Ihren Kundendaten.</span>
        <span *ngIf="enviroment.envName != '110129-gloria'" class="formularHinweis">Bitte ausfüllen, wenn abweichend vom Vertragspartner.</span>
        <div [ngClass]="detectedPlattform != 'WEB' ? 'personenAngabenApp' : 'personenAngabenWeb'" id="essensteilnehmer">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['essensteilnehmer']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #rechnungskunde appRegistrierungFormularParser [viewChild]="rechnungskunde" [formConfig]="feldConfig" formAbschnitt="rechnungskunde"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'" id="rk">
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="rechnungskundeKarte" class="kartenKopf">
              <strong>Rechnungskunde</strong><span> (falls abweichend)</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span *ngIf="enviroment.envName == '110129-gloria'"  class="formularHinweis">Bitte ausfüllen, wenn abweichend von Ihren Kundendaten.</span>
        <span *ngIf="enviroment.envName != '110129-gloria'" class="formularHinweis">Bitte ausfüllen, wenn abweichend vom Vertragspartner.</span>
        <div [ngClass]="detectedPlattform != 'WEB' ? 'personenAngabenApp' : 'personenAngabenWeb'" id="rechnungskunde">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['rechnungskunde']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #bankverbindung appRegistrierungFormularParser [viewChild]="bankverbindung" [formConfig]="feldConfig" formAbschnitt="bankverbindung"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'" id="bankverbindung">
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="bankverbindungKarte" class="kartenKopf">
              <strong>Bankverbindung</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span *ngIf="enviroment.envName != '110129-gloria'" class="formularHinweis bankVerbindungHinweis">
          Mit dem Eintragen Ihrer Bankdaten ermächtigen Sie uns zur SEPA-Lastschift und erteilen uns ein SEPA-Lastschriftmandat.
        </span>
        <span *ngIf="enviroment.envName == '110129-gloria'" class="formularHinweis bankVerbindungHinweis">
          Mit dem Eintragen Ihrer Bankdaten ermächtigen Sie uns zur SEPA-Lastschrift und erteilen uns ein SEPA-Lastschriftmandat. Sollten Sie keine Bankdaten angeben und per Überweisung zahlen wollen, so müssen wir für den deutlich höheren Verwaltungsaufwand eine Gebühr in Höhe von 2,00 € je Rechnungswoche erheben.
        </span>
        <div id="bankverbindungMobil" [class.bankverbindungWeb]="detectedPlattform == 'WEB'">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['bankverbindung']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #zahlung appRegistrierungFormularParser [viewChild]="zahlung" [formConfig]="feldConfig" formAbschnitt="zahlung"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'" id="zahlung">
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="zahlungKarte" class="kartenKopf">
              <strong>Zahlung</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="zahlungMobil" [class.zahlungWeb]="detectedPlattform == 'WEB'">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['zahlung']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #einrichtung appRegistrierungFormularParser [viewChild]="einrichtung" [formConfig]="feldConfig" formAbschnitt="einrichtung"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'" id="einrichtung">
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="einrichtungKarte" class="kartenKopf">
              <strong registrierungsEinrichtungBezeichnung >Einrichtung</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="einrichtungMobil"  [class.einrichtungWeb]="detectedPlattform == 'WEB'">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['einrichtung']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #anmeldung appRegistrierungFormularParser [viewChild]="anmeldung" [formConfig]="feldConfig" formAbschnitt="anmeldung"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'" id="anmeldung">
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="anmeldungKarte" class="kartenKopf">
              <strong>Anmeldung</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="anmeldungMobil"  [class.anmeldungWeb]="detectedPlattform == 'WEB'">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['anmeldung']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #zusatz appRegistrierungFormularParser [viewChild]="zusatz" [formConfig]="feldConfig" formAbschnitt="zusatz"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'" id="zusatz">
        <mat-expansion-panel-header (click)="onKarteAufklappen($event)">
          <mat-panel-title>
            <div id="zusatzKarte" class="kartenKopf">
              <strong>Zusätzliche Informationen</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="zusatzMobil"  [class.zusatzWeb]="detectedPlattform == 'WEB'">
          <ng-container
            appRegistrierungFormularRenderer
            [vertragsId]="vertragsId"
            [feldConfig]="feldConfig['zusatz']"
            [formGroup]="formGroup"
            [mandant]="mandant"
          ></ng-container>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <mat-card id="registrierungsFormularOptions" *ngIf="mandant.registrierFormen[this.vertragsId].registrierArt !== 1 && detectedPlattform != 'WEB'">
    <span class="vertragspartnerHinweis">* = Pflichtfelder</span>
    <ng-container *ngFor="let data of gesetzestexte">
      <rechtstexte *ngIf="data" [Titel]="data.name" [Inhalt]="data.text"></rechtstexte>
    </ng-container>
    <button id="speichern" mat-flat-button >Registrierung bestätigen</button>
    <button id="abbrechen" mat-flat-button type='button' (click)="verlinkungZumLogin()">Registrierung verwerfen</button>
  </mat-card>
</form>

import { Injectable } from '@angular/core';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { map, switchMap } from 'rxjs/operators';
import { ApiCallService } from '../api/api-call.service';

import {BehaviorSubject, of, timer} from 'rxjs';
import { MandantenService } from '../mandanten/mandanten.service';
import { environment } from 'environments/environment';
import {BenachrichtigungService} from '../prozesse/benachrichtigung.service';

@Injectable()
export class KundeService {

  public werbebannerModus:string
  public kunde: any = false
  public update: BehaviorSubject<any> = new BehaviorSubject(true)
  public kundeGuthaben: BehaviorSubject<any> = new BehaviorSubject(true)

  constructor(
    private api_call_service: ApiCallService,
    private datenbank_service: DbReaderService,
    private mandant_service: MandantenService,
    private benachrichtigung_service: BenachrichtigungService,
  ) {
    this.werbebannerModus = environment.type == 'web' ? 'Web' : 'App'
  }

  public _kunde_get(){
    return this.datenbank_service.get('active', 'User').pipe(
      switchMap((id) => {
        if (id){
          return this.datenbank_service.get(id, 'User')
        }else{
          return of(false)
        }
      })
    )
  }

  public aktualisiereKundeInDerDatenbank(kunde){
    this.datenbank_service.update(kunde, kunde.kundeId, 'User')
    this.update.next(true)
  }

  public kunde_get() {
    let parameter = {
      mandantId: this.mandant_service.mandant_id.getValue()
    }
    return this.api_call_service.request('/call', 'kunde/getAPI_1_5', parameter)
  }

  public kunde_update(kunde) {
    let _kunde = { ...kunde }
    let parameter = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      kunde: JSON.stringify(_kunde)
    }
    return this.api_call_service.request('/call', 'kunde/update', parameter)
  }

  public kunde_login(login) {
    let parameter = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      login: login.user,
      passwd: login.password,
      lang: 'de'
    }
    // Todo muss zur umstellung bei der 1_5 geändert werden & multilang muss mit eingebunden werden in den parametern
    return this.api_call_service.request('/login', 'kunde/loginAPI_1_5', parameter)
      .pipe(map((kunde: any) => {
        this.benachrichtigung_service.benachrichtigung_aktualisieren(kunde, false)
        this.mandant_service.mandant_login(kunde.mandantId)
        this.datenbank_service.add(kunde, kunde.kundeId, 'User')
        this.datenbank_service.add(kunde.kundeId, 'active', 'User')
        localStorage.setItem('active', kunde.kundeId)
        this.update.next(true)
        this.kundeGuthaben.next(this.kunde.guthaben)
        this.kunde = true
        return kunde
      }))
  }

  public kunde_session_update(password) {

    let parameter = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      passwd: password.toString()
    }
    return this.api_call_service.request('/session-update', 'kunde/login', parameter)
      .pipe(map((kunde: any) => {
        this.benachrichtigung_service.benachrichtigung_aktualisieren(kunde, false)
        this.mandant_service.mandant_login(kunde.mandantId)
        this.datenbank_service.add(kunde, kunde.kundeId, 'User')
        this.datenbank_service.add(kunde.kundeId, 'active', 'User')
        localStorage.setItem('active', kunde.kundeId)
        this.update.next(true)
        this.kunde = true
        return kunde
      }))
  }

  public kunde_logout(id) {
    if (localStorage.getItem('active') == id){
      localStorage.removeItem('active')
    }
    localStorage.removeItem(id)
    localStorage.removeItem('benachrichtigung')
    console.log(`Werbepopup_${id}`)
    localStorage.removeItem(`Werbepopup_${id}`)
    this.mandant_service.mandant_login(environment.mandant_id)
    this.update.next(true)
    this.kunde = false
    return this.datenbank_service.delete(id)
  }

  public kunde_passwort_vergessen(kundennummer) {
    let params = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      kundenNr: kundennummer,
      urlEndpoint: "/reset_password/{{id}}"
    }
    return this.api_call_service.request('/public', 'passwort/require', params)
  }

  public kunde_passwort_reseten(id) {
    let params = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      linkId: id
    }
    return this.api_call_service.request('/public', 'passwort/reset', params)
  }

  public kunde_validate(formular) {
    let params = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      neuKunde: formular
    }
    return this.api_call_service.request('/public', 'kunde/validate', params)
  }

	public kunde_commit(formular){
    let parameter = {
        mandantId: this.mandant_service.mandant_id.getValue(),
        neuKunde: formular
    }
    return this.api_call_service.request('/public', 'kunde/commit', parameter)
  }

  public kunde_verify(neukunde){
    let parameter = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      neukundeId: neukunde
    }
    return this.api_call_service.request('/public', 'kunde/verify', parameter)
  }
}

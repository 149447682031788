import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'wartungsabgleich',
    templateUrl: './wartungsabgleich.component.html',
    styleUrls: ['./wartungsabgleich.component.css']
})
export class WartungsabgleichComponent implements OnInit {

  constructor(){}

  ngOnInit() {}
}

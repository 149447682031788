import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'monat'
})
export class MonatPipe implements PipeTransform {

  monate:Array<any> = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

  transform(value: any, args?: any): any {
    return this.monate[value-1]
  }

}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class SpeiseplanEinzelbestellerService {

  public os
  public bearbeitet : BehaviorSubject<boolean | object> = new BehaviorSubject(false)

  constructor() {
    this.os = environment.type == 'web' ? 'web': 'app'
  }

  bestellungErkennen(tag){
    let bestellung: any
    let status = false
    for (bestellung of Object.values(tag.bestellMenues)){
      if (bestellung.mengeAlt > 0) {
        status = true
      }
    }
    return status
  }

  statusTag(menue, bestelltag){
    if (menue.mengeNeu > 0){
      this.bearbeitet.next(menue)
    } else {
      let bestellung
      let stati = false
      for (bestellung of Object.values(bestelltag.bestellMenues)){
        if (bestellung.mengeNeu > 0 && bestellung.mengeNeu != bestellung.mengeAlt){
          stati = true
          break;
        }
      }
      if (stati){
        this.bearbeitet.next(menue)
      } else {
        this.bearbeitet.next(false)
      }
    }
  }

}


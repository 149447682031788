<div
  id="speiseplanMenu"
  [class.normal]="bestellung.mengeNeu == bestellung.mengeAlt && bestellung.mengeAlt > 0"
  [class.positiv]="bestellung.mengeNeu > bestellung.mengeAlt"
  [class.negativ]="bestellung.mengeAlt > bestellung.mengeNeu"
  [class.markiert]="config.bestellungen[menu.menueTextId].markierung"
  [class.aktiv]="this.kunde && plattform == 'web' ? menu.menueTextId == aktiv : menu.menueId == aktiv && speiseplanMenuDatum == datum"
  >
  <speiseplanMenuBeschreibung
    [menu]="menu"
    [mandant]="mandant"
    (click)="open_details()"
  ></speiseplanMenuBeschreibung>
  <speiseplanMenuBestellung
    [menu]="menu"
    [mandant]="mandant"
    [bestellungen]="bestellungen"
    [bestellung]="bestellung"
    [config]="config.bestellungen[menu.menueTextId]"
    *ngIf="!gesperrt && kunde && bestellungen"
  >
  </speiseplanMenuBestellung>
  <div id="gesperrt" *ngIf="gesperrt">
     <mat-icon id="speiseplanBestellungGesperrt">lock</mat-icon>
  </div>
</div>

<div id="speiseplan-bestellung" *ngIf="config">
    <ng-container *ngIf="!config.gesperrt">
        <div class="speiseplan-bestellung-dashboard">
          <img src="{{ bestell_regeln | bestellschlussicon }}" />
            <div class="speiseplan-bestellung-optionen">
                <button
                    *ngIf="config.mehrfachbestellbar && !menuSync"
                    class="bad"
                    [disabled]="(bestell_regeln.abbestellschluss && bestellung.mengeNeu == bestellung.mengeAlt) || bestell_regeln.min == bestellung.mengeNeu"
                    (click)="bestellung_minus($event)"
                ><mat-icon>remove</mat-icon></button>
              <button
                *ngIf="config.mehrfachbestellbar && !menuSync"
                class="good"
                [disabled]="(bestell_regeln.bestellschluss && bestellung.mengeNeu == bestellung.mengeAlt) || bestell_regeln.max == bestellung.mengeNeu"
                (click)="bestellung_plus($event)"
              ><mat-icon>add</mat-icon></button>
                <input
                *ngIf="config.mehrfachbestellbar && !menuSync"
                    type="number"
                    [(ngModel)]="bestellung.mengeNeu"
                    (change)="bestellung_mehrfach($event)"
                    [min]="bestell_regeln.min"
                    [max]="bestell_regeln.max"
                    [readOnly]="bestell_regeln.abbestellschluss && bestell_regeln.bestellschluss"
                />
                <div
                  *ngIf="!config.mehrfachbestellbar || menuSync"
                  (click)="bestellung_einzeln()"
                  class="speiseplan-bestellung-einzeln"
                >
                  <mat-icon *ngIf="bestellung.mengeNeu > 0">checked</mat-icon>
                </div>
            </div>
        </div>
        <div class="speiseplan-bestellung-error" *ngIf="bestellung.error">{{ bestellung.error }}</div>
    </ng-container>
    <ng-container *ngIf="config.gesperrt">
        <mat-icon id="speiseplanBestellungGesperrt">lock</mat-icon>
    </ng-container>
</div>

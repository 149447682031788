<div class="offentlicherSpeiseplaene" *ngIf="mandantRegistrierFormenZaehlen() <= 3">
  <a [routerLink]="'/speiseplan-mandant/' + speisePlanId.value.splanId"
     routerLinkActive="router-link-active"
     class="offentlicherSpeiseplanMenuLink"
     *ngFor="let speisePlanId of mandant.speisePlanIds | keyvalue"
  >
    <mat-icon>restaurant</mat-icon>
    <span>{{speisePlanId.value.bezeichnung}}</span>
  </a>
</div>

<ng-container *ngIf="mandantRegistrierFormenZaehlen() > 3">
  <a routerLink="speiseplan-overview"
     [class.router-link-active]="splanLinkSelect"
     class="offentlicherSpeiseplanMenuLink"
  >
    <mat-icon>restaurant</mat-icon>
    <span>{{ splanName }}</span>
  </a>
</ng-container>

import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {DbReaderService} from 'services/db-reader/db-reader.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {DateiService} from 'services/prozesse/datei.service';
import {SpeiseplanService} from 'submodul/obs.speiseplan/services/speiseplan.service';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {Platform} from '@angular/cdk/platform';
import {StandartDialogComponent} from '../../../obs.shared/dialog/standart-dialog/standart-dialog.components';

@Component({
  selector: 'speiseplan-submenu',
  templateUrl: './speiseplan-submenu.component.html',
  styleUrls: ['./speiseplan-submenu.component.css'],
})
export class SpeiseplanSubmenuComponent implements OnInit {

  @Input('kunde') kunde
  @Input('zeitraum') zeitraum
  private speiseplan
  public submenuAktiv = false
  public bestellschluss

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<SpeiseplanSubmenuContentComponent>,
    private speiseplanService: SpeiseplanService,
    private matSheet: MatBottomSheet,
    private router: Router,
    private dbReaderService: DbReaderService,
  ) {
  }

  ngOnInit() {
    this.speiseplanDatein()
    this.dbReaderService.get('bestellschluss', 'Cache').subscribe((value) => {
      if (this.submenuAktiv || value){
        this.bestellschluss = value
        this.submenuAktiv = true
      }
    })
  }

  ngOnChanges() {
    this.speiseplanDatein()
  }

  public speiseplanDatein(){
    let speiseplanObserver: Observable<any>
    if (this.kunde){
      speiseplanObserver = this.speiseplanService.speiseplan_kunde(
        this.kunde.bestellerId,
        this.zeitraum.start,
        this.zeitraum.ende
      )
    }else{
      const aktuelleUrl = this.router.url.split('/')
      speiseplanObserver = this.speiseplanService.speiseplan_mandant(
        aktuelleUrl[aktuelleUrl.length - 1],
        this.zeitraum.start,
        this.zeitraum.ende
      )
    }
    speiseplanObserver.subscribe(
      (speiseplan) => {
        this.speiseplan = speiseplan
        if (this.submenuAktiv || Object.keys(speiseplan.splanPdfs).length > 0){
          this.submenuAktiv = true
        }
      })
  }

  bottomSheet() {
    this.matSheet.open(SpeiseplanSubmenuContentComponent, {
      data: {
        bestellschluss: this.bestellschluss,
        pdf: this.speiseplan.splanPdfs
      }
    })
  }
}

@Component({
  selector: 'speiseplan-submenu-content',
  templateUrl: 'speiseplan-submenu-content.component.html',
  styleUrls: ['./speiseplan-submenu.component.css'],
})
export class SpeiseplanSubmenuContentComponent implements OnInit{

  public pdfsAktiv = false

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private matDialog: MatDialog,
    public dateiService: DateiService,
    private dbReaderService: DbReaderService,
    private detectedPlatform: Platform
  ) {}

  ngOnInit() {
    this.pdfsAktiv = Object.keys(this.data.pdf).length > 0
  }

  public bestellSchlussText(){
    this.dbReaderService.get('bestellschluss', 'Cache').subscribe((value) => {
      this.matDialog.open(StandartDialogComponent, {
        panelClass: 'dialog-container-bestellschluss',
        height: '500px',
        data: {
          title: "Bestellschlussinfo",
          text: value,
          type: 'bestellschluss'
        }
      })
    })
  }

  public speiseplanPdfs(){
    this.dateiService.speiseplanPDF(Object.values(this.data.pdf)[0]).subscribe((pdf) => {
      setTimeout(() => { window.open(URL.createObjectURL(pdf)) },50)
    })
  }
}

<div id="registrierungZusammenfassung" [class.web]="detectedPlattform == 'WEB'" *ngIf="mandant && formData">
  <div id="registrierungZusammenfassungCheck">
    <mat-icon>info</mat-icon>
    <span>Bitte prüfen Sie Ihre Angaben!</span>
  </div>
  <mat-accordion>
    <mat-expansion-panel expanded *ngIf="mandant.registrierFormen[this.vertragsId].registrierArt !== 2" id="vertrag_text"  [class.expansionPanelWeb]="detectedPlattform == 'WEB'">
      <mat-expansion-panel-header>
        <mat-panel-title><strong>{{ mandant.registrierFormen[vertragsId].name }}</strong></mat-panel-title>
      </mat-expansion-panel-header>
      <app-registrierung-vertrag [vertragsId]="vertragsId" [mandant]="mandant" [formGroup]="formData"></app-registrierung-vertrag>
    </mat-expansion-panel>
    <mat-card class="registrierungZusammenfassungKarte" id="vertragspartner">
      <div class="kartenKopf">
        <strong *ngIf="enviroment.envName == '110129-gloria'">Ihre Kundendaten</strong>
        <ng-container *ngIf="enviroment.envName != '110129-gloria'"><strong>Vertragspartner</strong> <span>(ggf. gesetzlicher Vertreter)</span></ng-container>
      </div>
      <div [ngClass]="detectedPlattform != 'WEB' ? 'personenAngabenApp' : 'personenAngabenAbschluss'"
          appRegistrierungZusammenfassungRenderer
          [formData]="formData"
          [formConfig]="formConfig['vertragspartner']"
        >
      </div>
    </mat-card>

    <mat-card class="registrierungZusammenfassungKarte"
              appRegistrierungZusammenfassungParser
              [formData]="formData"
              [formConfig]="formConfig['essensteilnehmer']"
              id="et"
    >
      <strong>Essensteilnehmer</strong>
      <div id="essensteilnehmer"  [ngClass]="detectedPlattform != 'WEB' ? 'personenAngabenApp' : 'personenAngabenAbschluss'"
           appRegistrierungZusammenfassungRenderer
           [formData]="formData"
           [formConfig]="formConfig['essensteilnehmer']"
      >
      </div>
    </mat-card>

    <mat-card class="registrierungZusammenfassungKarte"
              appRegistrierungZusammenfassungParser
              [formData]="formData"
              [formConfig]="formConfig['rechnungskunde']"
              id="rk"
    >
      <strong>Rechnungskunde</strong>
      <div id="rechnungskunde" [ngClass]="detectedPlattform != 'WEB' ? 'personenAngabenApp' : 'personenAngabenAbschluss'"
           appRegistrierungZusammenfassungRenderer
           [formData]="formData"
           [formConfig]="formConfig['rechnungskunde']"
      >
      </div>
    </mat-card>

    <mat-card class="registrierungZusammenfassungKarte"
      appRegistrierungZusammenfassungParser
              [formData]="formData"
              [formConfig]="formConfig['bankverbindung']"
              id="bankverbindung"
    >
      <strong>Bankverbindung</strong>
      <div id="bankverbindungMobil" [class.bankverbindungWeb]="detectedPlattform == 'WEB'"
           appRegistrierungZusammenfassungRenderer
           [formData]="formData"
           [formConfig]="formConfig['bankverbindung']"
      >
      </div>
    </mat-card>

    <mat-card class="registrierungZusammenfassungKarte"
              appRegistrierungZusammenfassungParser
              [formData]="formData"
              [formConfig]="formConfig['zahlung']"
              id="zahlung"
    >
      <strong>Zahlung</strong>
      <div id="zahlungMobil" [class.zahlungWeb]="detectedPlattform == 'WEB'"
           appRegistrierungZusammenfassungRenderer
           [formData]="formData"
           [formConfig]="formConfig['zahlung']"
      >
      </div>
    </mat-card>

    <mat-card class="registrierungZusammenfassungKarte"
              appRegistrierungZusammenfassungParser
              [formData]="formData"
              [formConfig]="formConfig['einrichtung']"
              id="einrichtung"
    >
      <strong>Einrichtung</strong>
      <div id="einrichtungMobil" [class.einrichtungWeb]="detectedPlattform == 'WEB'"
           appRegistrierungZusammenfassungRenderer
           [formData]="formData"
           [formConfig]="formConfig['einrichtung']"
      >
      </div>
    </mat-card>

    <mat-card class="registrierungZusammenfassungKarte"
              appRegistrierungZusammenfassungParser
              [formData]="formData"
              [formConfig]="formConfig['anmeldung']"
              id="anmeldung"
    >
      <strong>Anmeldung</strong>
      <div id="anmeldungMobil" [class.anmeldungWeb]="detectedPlattform == 'WEB'"
           appRegistrierungZusammenfassungRenderer
           [formData]="formData"
           [formConfig]="formConfig['anmeldung']"
      >
      </div>
    </mat-card>

    <mat-card class="registrierungZusammenfassungKarte">
      <strong>Zusätzliche Informationen</strong>
      <div id="zusatzMobil" [class.zusatzAbschluss]="detectedPlattform == 'WEB'"
           appRegistrierungZusammenfassungRenderer
           [formData]="formData"
           [formConfig]="formConfig['zusatz']"
           id="zusatz"
      >
      </div>
    </mat-card>
  </mat-accordion>
  <mat-card id="registrierungZusammenfassungOptionen" *ngIf="detectedPlattform != 'WEB'">
    <rechtstexte *ngIf="mandant.betreiberDaten.agb !== ''" [Titel]="'AGB'" [Inhalt]="mandant.betreiberDaten.agb"></rechtstexte>
    <rechtstexte *ngIf="mandant.betreiberDaten.widerruf !== ''" [Titel]="'Wiederruf'" [Inhalt]="mandant.betreiberDaten.widerruf"></rechtstexte>
    <rechtstexte *ngIf="mandant.betreiberDaten.datenschutz !== ''" [Titel]="'Datenschutz'" [Inhalt]="mandant.betreiberDaten.datenschutz"></rechtstexte>
    <button class="good" mat-flat-button (click)="kundeCommitApi()">Vertrag anbahnen</button>
    <button class="warn" mat-flat-button (click)="verlinkungZumFormular()">Registrierung bearbeiten</button>
    <button class="bad" mat-flat-button (click)="verlinkungZumLogin()">Registrierung abbrechen</button>
  </mat-card>
</div>

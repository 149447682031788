<div id="speiseplanSubmenu" *ngIf="data">
  <button *ngIf="data.bestellschluss" mat-button (click)="bestellSchlussText()">
    <mat-icon>alarm</mat-icon>
    <span>Bestellschlussinfo</span>
  </button>
  <button *ngIf="pdfsAktiv" mat-button (click)="speiseplanPdfs()">
    <mat-icon>picture_as_pdf</mat-icon>
    <span>Speiseplan PDF</span>
  </button>
</div>

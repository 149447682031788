import {Injectable} from '@angular/core';
import {SeitenleisteInhalt} from '../../obs.seitenleiste/seitenleisteInhalt.class';
import {SpeiseplanDetailsComponent} from '../einzelbesteller/speiseplanDetails/speiseplanDetails.component';
import {SeitenleisteService} from '../../obs.seitenleiste/seitenleiste.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SpeiseplanMenuService {

  public menuDetailsObserver: BehaviorSubject<boolean | object> = new BehaviorSubject(false)

  constructor(
    private seitenleistenService: SeitenleisteService,
  ) {}

  public speiseplanMenuBeschreibungTranslate(speiseplan, menu){
    let menutext = speiseplan.menutexte[menu.menueTextId]
    if (menutext){
      menu['menueText'] = menutext.menueText
      menu['bezeichnung'] = menutext.bezeichnung
      menu['symbol'] = menutext.symbol
      menu['menueGruppe'] = menutext.menueGruppe
      menu['naehrwerte'] = menutext.naehrwerte
      menu['portionsGroesse'] = menutext.portionsGroesse
      menu['allergene'] = menutext.allergeneIds
      menu['inhaltsstoffe'] = menutext.inhaltsstoffeIds
      menu['zusatzstoffe'] = menutext.zusatzstoffeIds
    }
    return menu
  }

  public speiseplanMenuGesperrt(datum, bestellung, config, speiseplan):boolean{
    if (config.bestellungen[bestellung.menueTextId].gesperrt){
      return true
    }
    if (speiseplan.zustellpause) {
      for (var obj in speiseplan.zustellpause) {
        var von = speiseplan.zustellpause[obj].von;
        var bis = speiseplan.zustellpause[obj].bis;
        if (bis !== '1970-01-01' && datum <= bis && datum >= von) {
          return true
        }
        if (bis == '1970-01-01' && datum >= von) {
          return true
        }
      }
    }
    return false
  }

  public seitenleistenDetailsAktualisieren(mandant, menu) {
    menu['bezeichnung'] = menu.bezeichnung
    menu['menueText'] = menu.menueText
    menu['naehrwerte'] = menu.naehrwerte
    this.menuDetailsObserver.next(menu)
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      SpeiseplanDetailsComponent, {
        mandant: mandant,
        speiseplanDetailsObserver: this.menuDetailsObserver
      })
    )
  }

}

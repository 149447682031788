<div id="warenkorb-gesamtpreis" *ngIf="preis_anzeige">
  <div id="warenkorb-gesamtpreis-hinweis">
    <strong class="titel">Gesamtpreis (</strong>
    <span>inklusive MwSt.</span>
    <strong>)</strong>
  </div>
  <span *ngIf="bestellungen_meta.anzahl > 0 && bestellungen">{{ bestellungen.gesamtPreis | euroPrice }}</span>
  <span *ngIf="bestellungen_meta.anzahl == 0 && !bestellungen">0,00€</span>
</div>
<div id="warenkorb-optionen">
  <span class="smaller" [innerHTML]="warenkorb_titel">{{warenkorb_titel}}</span>
  <button [disabled]="bestellungen_meta.anzahl == 0 || (bestellungen && bestellungen.error)" (click)="bestellung_commit()">
    <span> Zum genannten Preis bestellen</span>
  </button>
  <button mat-raised_button [disabled]="bestellungen_meta.anzahl == 0" (click)="confirmDialog()">
    <span>Bestelländerung zurücksetzen</span>
  </button>
  <span class="smaller">Im Falle einer Abbestellung fallen keine Kosten an.</span>
</div>
<speiseplan-glossar [mandant]="mandant"></speiseplan-glossar>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {ConfirmDialogModel} from '../../../dialog/confirm-dialog/confirm-dialog.components';

@Component({
    selector: 'wichtigeMitteilungen-dialog',
    templateUrl: './wichtigeMitteilungen-dialog.component.html',
    styleUrls: ['./wichtigeMitteilungen-dialog.component.css'],
})
export class WichtigeMitteilungenDialogComponent implements OnInit {

    constructor(
      @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit(){
      const wrapperDiv = document.createElement('div');
      wrapperDiv.innerHTML = atob(this.data.global[0].content);
      document.getElementById('global_body').appendChild(wrapperDiv);

    }
}

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subscription, zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {BestellungService} from 'services/bestellung/bestellung.service';
import {KundeService} from 'services/kunden/kunde.service';
import {SpeiseplanService} from 'submodul/obs.speiseplan/services/speiseplan.service';
import {MandantenService} from '../../../services/mandanten/mandanten.service';
import {SeitenleisteInhalt} from '../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {
  BestellhistorieSeitenleisteComponent
} from '../../../submodul/obs.seitenleiste/components/bestellhistorie/bestellhistorie.seitenleiste.component';
import {SeitenleisteService} from '../../../submodul/obs.seitenleiste/seitenleiste.service';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';
import {normalizeOptimization} from '@angular-devkit/build-angular/src/utils';
import * as moment from 'moment/moment';
import {
  BestellhistorieSeitenleisteService
} from '../../../submodul/obs.seitenleiste/components/bestellhistorie/bestellhistorieSeitenleistenService';

@Component({
  selector: 'web-bestellhistorie',
  templateUrl: './web-bestellhistorie.component.html',
  styleUrls: ['./web-bestellhistorie.component.css'],
})

export class WebBestellhistorieComponent implements OnInit {

  @ViewChild('speiseplan_details') speiseplan_details: any
  public kunde = null
  public mandant: any
  public bestellung: any
  public api_zeitraum
  public zeitraumVorgabe = null
  public preis_anzeige
  public emitterSubscription: Subscription
  public speiseplanKunde
  public speiseplanBestellung
  public bestellungLeer = true

  constructor(
    private speiseplan_service: SpeiseplanService,
    private kunden_service: KundeService,
    private mandant_service: MandantenService,
    private bestellung_service: BestellungService,
    private route: ActivatedRoute,
    private seitenleistenService: SeitenleisteService,
    private benachrichtigung_service: BenachrichtigungService,
    private bestellhistorieSeitenleisteService: BestellhistorieSeitenleisteService,
  ) { }

  ngOnInit() {
    this.seitenleistenService.emitterSeitenleiste = new BehaviorSubject(null)
    this.emitterSubscription = this.seitenleistenService.emitterSeitenleiste.subscribe(event => {
      this.seitenLeistenTrigger(event)
    })
    zip(
      this.mandant_service.mandant_get(),
      this.kunden_service._kunde_get(),
      this.speiseplan_service.speiseplan_zeitraum()
    ).subscribe(internResponse => {
      this.mandant = internResponse[0]
      if (this.mandant.preisPositionInSplan == 'none'){
        this.preis_anzeige = false
      } else {
        this.preis_anzeige = true
      }
      this.kunde = internResponse[1]
      this.benachrichtigung_service.setze_benachrichtigungen(this.kunde)
      this.api_zeitraum = internResponse[2]
      if (this.route.snapshot.paramMap.get('zeitraum')) {
        //this.speiseplan_laden(JSON.parse(this.route.snapshot.paramMap.get('zeitraum')))
        this.zeitraumVorgabe = JSON.parse(this.route.snapshot.paramMap.get('zeitraum'))
      }
      zip(
        this.speiseplan_service.speiseplan_kunde(this.kunde.bestellerId, this.api_zeitraum.von, this.api_zeitraum.bis),
        this.bestellung_service.bestellung_kunde(this.kunde.bestellerId, this.api_zeitraum.von, this.api_zeitraum.bis)
      ).subscribe(apiResponse => {
        console.log(this.zeitraumVorgabe)
        this.speiseplanKunde = apiResponse[0]
        this.speiseplanBestellung = apiResponse[1]
        this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
          BestellhistorieSeitenleisteComponent, {
            api_zeitraum: this.api_zeitraum,
            zeitraumVorgabe: this.zeitraumVorgabe,
            bestellung: this.speiseplanBestellung.kunden[this.kunde.bestellerId],
            preis_anzeige: this.preis_anzeige,
            mandant: this.mandant,
          })
        )
      })
    })
  }

  speiseplan_laden(zeitraum) {
    this.bestellung = {
      menutexte: this.speiseplanKunde.menuetexte,
      tage: {},
    }
    let bestellhistoriePreis = 0

    for (let bestellTag in this.speiseplanBestellung.kunden[this.kunde.bestellerId].tage){
      let unixBestellTag = moment.default(bestellTag).unix()
      if (zeitraum.von.unix() <= unixBestellTag && zeitraum.bis.unix() >= unixBestellTag){
        let tag = this.speiseplanBestellung.kunden[this.kunde.bestellerId].tage[bestellTag]
        for (let menu_id in tag.bestellMenues) {
          let menu = tag.bestellMenues[menu_id]
          if (menu.mengeNeu == menu.mengeAlt) {
            delete tag.bestellMenues[menu_id]
          }else{
            bestellhistoriePreis += menu.gesamtBetrag.betrag
          }
        }
        if (Object.keys(tag.bestellMenues).length !== 0){
          this.bestellung.tage[bestellTag] = tag
        }
      }
    }
    this.bestellhistorieSeitenleisteService.bestellhistoriePreis.next(bestellhistoriePreis)
    this.bestellungLeer = Object.keys(this.bestellung.tage).length > 0 ? false : true
  }

  public seitenLeistenTrigger(event){
    if (event){
      this[event[0]](event[1])
    }
  }

  ngOnDestroy() {
    this.emitterSubscription.unsubscribe()
  }
}
